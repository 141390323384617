import React, { Component } from 'react';
import { SRLWrapper } from "simple-react-lightbox";

import main from '../../assets/img/main.png';
import hourly from '../../assets/img/hourly.png';
import sevenDay from '../../assets/img/sevenday.png';

const options = {
    buttons: {
        showDownloadButton: false,
        showThumbnailsButton: false,
    }
};

export default class Weather extends Component {
    render() {
        return (
            <div className="wrap">
                <h4>Link</h4>
                <p>Weather app</p>
                <p><a href="https://weatherapp.amangou.com" target="_blank" rel="noreferrer">https://weatherapp.amangou.com</a></p>
                <br />
                <h4>Stack</h4>
                <p>This simple application is built with <strong>React.js</strong> and <strong>Bootstrap</strong>. It is <strong>mobile-friendly</strong>.</p>
                <br />
                <h4>To Test-drive</h4>
                <p>Location is defaulted to Toronto, Ontario, if geo permission is denied.</p>
                <SRLWrapper options={options}>
                    <div className="gallery">
                        <img src={main} alt="" />
                        <img src={hourly} alt="" />
                        <img src={sevenDay} alt="" />
                    </div>
                </SRLWrapper>
            </div>
        )
    }
}
