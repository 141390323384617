import React, { Component } from 'react';
import { SRLWrapper } from "simple-react-lightbox";

import signUp from '../../assets/img/signup.png';
import mainMenu from '../../assets/img/mainmenu.png';
import meniList from '../../assets/img/menulist.png';
import trackOrders from '../../assets/img/trackorders.png';
import checkOut from '../../assets/img/checkout.png';
import placeOrder from '../../assets/img/placeorder.png';
import login from '../../assets/img/login.png';
import orders from '../../assets/img/orders.png';
import menuOne from '../../assets/img/menuone.png';
import menuTwo from '../../assets/img/menutwo.png';
import customers from '../../assets/img/customers.png';
import users from '../../assets/img/users.png';

const options = {
    buttons: {
        showDownloadButton: false,
        showThumbnailsButton: false,
    }
};

export default class Diner extends Component {
    render() {
        return (
            <SRLWrapper options={options}>
                <div className="wrap">
                    <h4>Links</h4>
                    <p>Download android application package(APK) for Diner</p>
                    <p><a href="https://drive.google.com/uc?export=download&id=1s0E6RzWtYyJC7aPN-8mFh0Jcydzo5OqL" target="_blank" rel="noreferrer">Android APK</a></p>
                    <p>Diner admin</p>
                    <p><a href="https://dineradmin.amangou.com" target="_blank" rel="noreferrer">https://dineradmin.amangou.com</a></p>
                    <br />
                    <h4>Stack</h4>
                    <p>The android app is built with <strong>MongoDB</strong> + <strong>Express.js</strong> + <strong>React Native</strong> + <strong>Node.js</strong>, plus <strong>Mobx</strong>, <strong>JWT</strong>, <strong>Stripe</strong> and a few other libraries. Its server is deployed to Heroku.</p>
                    <p>The web admin is built with <strong>React.js</strong>, plus <strong>Redux</strong>, <strong>Bootstrap</strong>, <strong>JWT</strong>, and <strong>Ant Design</strong>. It is <strong>mobile-friendly</strong>.</p>
                    <br />
                    <h4>To Test-drive</h4>
                    <br />
                    <div className="subsection">
                        <h5>Android app</h5>
                        <p>Download the APK and install it on an android cellphone. Once signed up, one can browse the menu and place an order. </p>
                    </div>
                    {/* <SRLWrapper options={options}> */}
                    <div className="gallery">
                        <img src={signUp} alt="" />
                        <img src={mainMenu} alt="" />
                        <img src={meniList} alt="" />
                        <img src={placeOrder} alt="" />
                        <img src={checkOut} alt="" />
                        <img src={trackOrders} alt="" />
                    </div>
                    {/* </SRLWrapper> */}
                    <br />
                    <div className="subsection">
                        <p><strong>NOTE</strong> that the payment is done with a <a href="https://stripe.com/docs/testing" target="_blank" rel="noreferrer">Stripe test card number</a>. For purposes of this demo, one only needs to fill in any future expiry date, any three-digit number CVV and any postal code.</p>
                    </div>
                    <br />
                    <div className="subsection">
                        <h5>Web admin</h5>
                        <p>One can either sign in as “<strong>Barry</strong>” (email: barry@eget.ca; password: Xz1234), who has full access,  or as “<strong>Larry</strong>” (email: larry@amet.net; password: Xz1234), who doesn’t have access to “Users”.</p>
                        <p>Once signed in, certain basic simulations are possible, including creating or dropping a diner menu item, looking up customer accounts, searching for or sorting existing orders, changing order status, and creating and managing users, etc. </p>
                    </div>
                    {/* <SRLWrapper options={options}> */}
                    <div className="gallery">
                        <img src={login} alt="" />
                        <img src={orders} alt="" />
                        <img src={menuOne} alt="" />
                        <img src={menuTwo} alt="" />
                        <img src={customers} alt="" />
                        <img src={users} alt="" />
                    </div>
                    {/* </SRLWrapper> */}
                </div>
            </SRLWrapper>
        )
    }
}
