import React, { Component } from 'react';
import { SRLWrapper } from "simple-react-lightbox";

import shopHome from '../../assets/img/shophome.png';
import shopCate from '../../assets/img/shopcate.png';
import shopItem from '../../assets/img/shopitem.png';
import shopCart from '../../assets/img/shopcart.png';
import adminItems from '../../assets/img/adminitems.png';
import adminCate from '../../assets/img/admincate.png';
import adminBrand from '../../assets/img/adminbrand.png';

const options = {
    buttons: {
        showDownloadButton: false,
        showThumbnailsButton: false,
    }
};

export default class Shop extends Component {
    render() {
        return (
            <SRLWrapper options={options}>
                <div className="wrap">
                    <h4>Link</h4>
                    <p>Shop-Admin</p>
                    <p><a href="https://shopdemo.amangou.com" target="_blank" rel="noreferrer">https://shopdemo.amangou.com</a></p>
                    <br />
                    <h4>Stack</h4>
                    <p>This single-page application is built with <strong>React.js</strong>, plus <strong>Redux</strong>, <strong>Material-UI</strong>, and <strong>Bootstrap</strong>. It is <strong>mobile-friendly</strong>.</p>
                    <br />
                    <h4>To Test-drive</h4>
                    <br />
                    <div className="subsection">
                        <h5>Shop</h5>
                        <p>Basic functions of an online shopping site are possible.</p>
                    </div>
                    <div className="gallery">
                        <img src={shopHome} alt="" />
                        <img src={shopCate} alt="" />
                        <img src={shopItem} alt="" />
                        <img src={shopCart} alt="" />
                    </div>
                    <br />
                    <div className="subsection">
                        <h5>Admin</h5>
                        <p>Basic CRUD operations are possible on the management of items, brands and categories. </p>
                    </div>
                    <div className="gallery">
                        <img src={adminItems} alt="" />
                        <img src={adminCate} alt="" />
                        <img src={adminBrand} alt="" />
                    </div>
                </div>
            </SRLWrapper>

        )
    }
}
