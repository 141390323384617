import React, { Component } from 'react';
import { Route, Switch, NavLink, Redirect, withRouter } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import Weather from './components/Weather';
import Shop from './components/Shop';
import Diner from './components/Diner';
import logo from './assets/img/logo-wide.png';
import './App.css';

class App extends Component {

  state = {
    mobileMenu: false,
    selected: ''
  };

  toggleMenu = () => {
    this.setState({ mobileMenu: !this.state.mobileMenu });
  };

  mobileMenuClick = (value) => {
    this.setState({ selected: value });
    this.toggleMenu();
  };

  componentDidMount() {
    let { location: { pathname } } = this.props;
    if (pathname === '/') pathname = '/diner'
    this.setState({ selected: pathname });
  };

  render() {
    const { mobileMenu, selected } = this.state;
    let header = '';
    switch (selected) {
      case '/diner':
        header = 'Diner'
        break;
      case '/shop':
        header = 'Shop-Admin'
        break;
      case '/weather':
        header = 'Weather'
        break;
      default:
        header = ''
        break;
    }

    return (
      <SimpleReactLightbox>
        <div className="container">
          <div className="header">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="links-wrap">
              <div className="links">
                <NavLink to="/diner" activeClassName="active-nav-link" className="nav-link"><div className="link">Diner</div></NavLink>
                <NavLink to="/shop" activeClassName="active-nav-link" className="nav-link"><div className="link">Shop-Admin</div></NavLink>
                <NavLink to="/weather" activeClassName="active-nav-link" className="nav-link"><div className="link">Weather</div></NavLink>
              </div>
            </div>
            <div className="mobile-button">
              {!mobileMenu
                ? <MenuIcon
                  fontSize='inherit'
                  className='toggle-button'
                  onClick={this.toggleMenu}
                />
                : <CloseIcon
                  fontSize='inherit'
                  className='toggle-button'
                  onClick={this.toggleMenu}
                />
              }
            </div>
          </div>
          <div className="mobile-menu">
            {
              !mobileMenu ? '' :
                <div className="mobile-menu-wrap">
                  <NavLink
                    to="/diner"
                    activeClassName="active-mobile-link"
                    className="mobile-link"
                    onClick={() => this.mobileMenuClick('/diner')}
                  >
                    <div className={selected === '/diner' ? "active-m-link" : "m-link"}>Diner</div>
                  </NavLink>
                  <NavLink
                    to="/shop"
                    activeClassName="active-mobile-link"
                    className="mobile-link"
                    onClick={() => this.mobileMenuClick('/shop')}
                  >
                    <div className={selected === '/shop' ? "active-m-link" : "m-link"}>Shop-Admin</div>
                  </NavLink>
                  <NavLink
                    to="/weather"
                    activeClassName="active-mobile-link"
                    className="mobile-link"
                    onClick={() => this.mobileMenuClick('/weather')}
                  >
                    <div className={selected === '/weather' ? "active-m-link" : "m-link"}>Weather</div>
                  </NavLink>
                </div>
            }
          </div>
          <div className="content">
            <h2 className='sectionHeader wrap'>{ header}</h2>
            <Switch>
              <Redirect exact from="/" to="/diner" />
              <Route path="/diner" component={Diner} />
              <Route path="/shop" component={Shop} />
              <Route path="/weather" component={Weather} />
            </Switch>
          </div>
          <div className="footer">&#169;Copyright 2021 </div>
        </div>
      </SimpleReactLightbox>
    );
  };
};
export default withRouter(App);